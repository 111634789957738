<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Header -->
      <div class="row">
        <div class="project-header my-auto">{{ calendar.header }}</div>
        <div class="ml-auto">
          <button v-if="role === 'STF' && !expired" v-on:click="notification()" class="noti-button mr-8">แจ้งเตือน</button>
          <router-link v-if="role === 'STF'" :to="'/calendar/' + this.$route.params.id + '/edit/'" class="mr-8">
            <button class="edit-button">แก้ไขกิจกรรม</button>
          </router-link>
          <button v-if="role === 'STF'" v-on:click="deleteCalendar(this.$route.params.id)" class="delete-button">ลบกิจกรรม</button>
        </div>
      </div>   
      <span>สร้างกิจกรรมเมื่อวันที่ {{ calendar.created }} น.</span>

      <!-- Description -->
      <div class="mt-16">{{ calendar.description }} ภายในวันที่ {{ calendar.active_to }} น.</div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import moment from "moment"

export default {
  name: "CalendarView",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      calendar: {
        header: ""
      },
      role: null,
      expired: false
    }
  },
  async created() {
    this.role = this.$store.getters.payload.role
    this.calendar = await this.$store.dispatch("getAnnouncementById", this.$route.params.id)
    this.calendar.created = moment(new Date(this.calendar.created)).locale('th').add(543, 'year').format('lll')
    this.calendar.noti_type = "cal"
    if(new Date(this.calendar.active_date_from) >= new Date() || new Date(this.calendar.active_date_to) <= new Date()) {
      this.expired = true
    }
    this.calendar.active_to = moment(new Date(this.calendar.active_date_to)).locale('th').add(543, 'year').format('lll')
    document.title = this.calendar.header + " | Carbon"
    this.loading = false
  },
  methods: {
    async deleteCalendar(calendar_id) {
      if (confirm("ต้องการลบกิจกรรม " + this.calendar.header +  " หรือไม่") == true) {
        const success = await this.$store.dispatch("deleteAnnouncementById", calendar_id)
        if(success) {
          alert("ลบกิจกรรมเสร็จสิ้น")
          this.$router.push("/")
        }
      }
    },
    async notification() {
      if (confirm("ต้องการแจ้งเตือนกิจกรรม " + this.calendar.header +  " หรือไม่") == true) {
        await this.$store.dispatch("notification", this.calendar)
        alert("แจ้งเตือนเสร็จสิ้น")
      }
    }
  },
}
</script>